import { gql, useMutation, useQuery } from '@apollo/client';
import React, {
  useEffect,
  useState,
} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import alertPic from '../../../assets/alert.svg';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import { formatDate } from '../../../common_lib_front/utilities/formatDate';
import { passCredOrNumber } from '../../../common_lib_front/utilities/passCredOrNumber';
import { PopUp } from '../../../components/popUp/popUp';
import style from './refundPass.module.css';

const REFUND = gql`
mutation RefundPass(
  $passId: String!
) {
  refundPass(
    passId: $passId
  ) {
    error
    success
  }
}
`;
type REFUND_VARS = {
  passId: string,
}
type REFUND_RES = {
  refundPass: backendResponse<undefined>
}

const CALC = gql`
query CalculateRefund(
  $passId: String!
) {
  calculateRefund(
    passId: $passId
  ) {
    error
    success
    data
  }
}
`;
type CALC_VARS = {
  passId: string,
}
type CALC_RES = {
  calculateRefund: backendResponse<any>
}

const GET_PASS = gql`
query Query(
  $passId: String!
) {
  getPass (
    passId: $passId
  ) {
    error
    success
    data {
      number
      externalCredentialNumber
      amtPaid
      passType
      paymentType
      startDate
      endDate
      passInfoId
    }
  }
}
`;
type GET_PASS_VARS = {
  passId: string,
}
type GET_PASS_RES = {
  getPass: backendResponse<{
    number: string,
    passInfoId: string,
    amtPaid: number,
    passType: string,
    paymentType: string,
    startDate: string,
    endDate: string,
  }[]>
}

const GET_PASSINFO = gql`
query getPassInfo($passInfoId: String!) {
  getPassInfo(passInfoId: $passInfoId) {
    success
    error
    data {
      name
    }
  }
}
`;
type GET_PASSINFO_VARS = {
  passInfoId: string,
}
type GET_PASSINFO_RES = {
  getPassInfo: backendResponse<{
    name: string,
  }>
}

export default function RefundPass(): React.ReactElement {
  const history = useHistory();
  const { passId } = useParams<{ passId: string }>();
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const [passInfoId, setPassInfoId] = useState('');

  const { data: passData, loading } = useQuery<
    GET_PASS_RES, GET_PASS_VARS
  >(GET_PASS, {
    variables: {
      passId,
    },
  });

  useEffect(() => {
    if (passData?.getPass?.data?.[0]?.passInfoId) {
      setPassInfoId(passData.getPass.data[0].passInfoId);
    }
  }, [passData]);

  const { data: passInfo } = useQuery<
    GET_PASSINFO_RES, GET_PASSINFO_VARS
  >(GET_PASSINFO, {
    variables: {
      passInfoId,
    },
    skip: !passInfoId,
  });

  // const max = useMemo<number>(() => {
  //   const amtPaid = passData?.getPass.data?.[0]?.amtPaid;
  //   if (typeof amtPaid === 'number') return amtPaid / 100;
  //   return 0;
  // }, [passData]);

  const {
    data: refund,
  } = useQuery<CALC_RES, CALC_VARS>(CALC, {
    variables: {
      passId,
    },
  });

  const max = refund?.calculateRefund?.data || 0;

  const [doRefundPass, { loading: refundLoading }] = useMutation<
    REFUND_RES, REFUND_VARS
  >(REFUND, {
    onCompleted: (d) => {
      if (d.refundPass.error) {
        setAlert(d.refundPass.error);
        setAlertColor('red');
      } else {
        history.goBack();
      }
    },
    onError: () => {
      setAlert('Something went wrong.');
      setAlertColor('red');
    },
  });

  return (
    <PopUp
      title="Refund"
      close={() => history.goBack()}
    >
      <div className={style.refundBox}>
        <GenericAlert
          color={alertColor}
          title={alert}
          hidden={!alert}
        />
        <img src={alertPic} alt="" className={style.alertPic} />
        <b className={style.warning}>
          Warning:
        </b>
        <br />
        <h4 className={style.subtitle}>
          Are you sure you want to refund this pass?
        </h4>
        <div className={style.tableWrapper}>
          <table className={style.table} cellSpacing={0}>
            <thead>
              <tr>
                <th>Pass Number</th>
                <th>Pass Type</th>
                <th>Refund Amount</th>
                <th>Start Date</th>
                <th>Valid Through</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{passCredOrNumber(passData?.getPass.data?.[0])}</td>
                <td>{passInfo?.getPassInfo.data?.name}</td>
                <td>
                  $
                  {max}
                </td>
                <td>{formatDate(new Date(passData?.getPass.data?.[0].startDate || ''))}</td>
                <td>{formatDate(new Date(passData?.getPass.data?.[0].endDate || ''))}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={style.refundButtonContainer}>
          <div className={style.refundBtn}>
            <Link to="/vendor/my-passes">
              <GenericButton
                title="Cancel"
                color="white"
                outline="small"
                size="large"
                disabled={refundLoading}
              />
            </Link>
          </div>
          <div className={style.refundBtn}>
            <GenericButton
              title="Refund"
              color="blue"
              outline="small"
              size="large"
              disabled={refundLoading}
              clickHandler={() => {
                doRefundPass({ variables: { passId } });
              }}
            />
          </div>
        </div>
      </div>
    </PopUp>
  );
}
