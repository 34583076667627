import React, { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import GenericAlert from '../../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../../common_lib_front/components/genericButton/genericButton';
import PassFormCollection from '../../../../components/passForm/passFormCollection';
import PassSummaryCollection from '../../../../components/passSummary/passSummaryCollection';
import RegistrationNavHeader from '../../../../components/registrationNavHeader/registrationNavHeader';
import { registrationStepProps } from '../wrapper';
import { deletePass } from './deletePass';
import style from './registerVehicle.module.css';
import useRegisterVehicle from './useRegisterVehicle';

export function RegisterVehicle(props: registrationStepProps): ReactElement {
  const {
    redirect,
    doSubmit,
    alert,
    setAlert,
    passes,
    setPasses,
    t,
    isRenewal,
    loading,
  } = useRegisterVehicle(props);

  // const [passes, setPasses] = useState<Array<PassInfo>>([]);
  // const [redirect, setRedirect] = useState<string>('');
  // const [registrationDates, setRegistrationDates] = useState<{
  //   startDate: string,
  //   endDate: string,
  // } | null>(null);
  // const { registrationId } = useParams<{ registrationId: string }>();
  // const [alert, setAlert] = useState<string>('');

  // const { t } = useTranslation();

  if (redirect) {
    return (
      <Redirect to={redirect} />
    );
  }

  return (
    <form
      className={style.container}
      onSubmit={(e:React.FormEvent) => {
        e.preventDefault();
        doSubmit();
      }}
    >
      <div className={`${style.box} white `}>
        <RegistrationNavHeader
          activeNum={1}
          title={`${t('Step 1')}: ${t('Register Your Pass')}`}
          // subtitle={t('step2_subtitle')}
          subtitle=""
        />
        <GenericAlert
          color="red"
          title={alert}
          hidden={!alert}
        />
        <div className={style.flexBox}>
          <div className={style.boxLeft}>
            <PassFormCollection
              data={passes}
              setData={setPasses}
              showSaveBtn={false}
              showTitle
              isRenewal={isRenewal}
              removeHandler={({ variables }) => {
                deletePass(variables)
                  .catch((err) => {
                    if (err instanceof Error) {
                      setAlert(err.message);
                    }
                  });
              }}
            />
          </div>

          <div className={style.boxRight}>
            <PassSummaryCollection
              showEditBtn={false}
              showVehicleInfo={false}
              showTotal
              data={passes}
              isRenewal={isRenewal}
            />
          </div>

        </div>
        <div className={style.btn}>
          <GenericButton
            size="large"
            color="blue"
            title={t('Continue')}
            type="submit"
            disabled={loading}
            // clickHandler={doSubmit}
          />
        </div>
      </div>
    </form>
  );
}

export default RegisterVehicle;
