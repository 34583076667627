import React, { ReactElement, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import style from './phoneInputWrapper.module.css';
import 'react-phone-input-2/lib/style.css';

interface InputFieldProps {
  placeHolder?: string;
  id?: string;
  value?: string;
  inputTitle?: string;
  inputStyle?: string;
  countryBtnStyle?: string;
  titleStyle?: string;
  standardInput?: boolean;
  smallStandardInput?: boolean;
  closedInput?: boolean;
  changeHandler: (val: string) => void;
  required?: boolean;
  disabled?: boolean;
}

export default function PhoneInputWrapper(props: InputFieldProps): ReactElement {
  const {
    placeHolder,
    id,
    value,
    changeHandler,
    inputTitle,
    inputStyle,
    countryBtnStyle,
    titleStyle,
    standardInput,
    smallStandardInput,
    closedInput,
    required,
    disabled,
  } = props;
  const [focused, setFocused] = useState<boolean>(false);
  let titleCname = titleStyle;
  let inptCname = inputStyle;
  let btnCname = countryBtnStyle;
  if (standardInput) {
    titleCname = `${style.standardTitle}`;
    inptCname = `${style.standardInput}`;
    btnCname = ` ${style.countryBtn}`;
  } else if (closedInput) {
    titleCname = `${style.closedTitle}`;
    inptCname = `${style.closedInput}`;
    btnCname = ` ${style.closedCountryBtn}`;
  } else if (smallStandardInput) {
    titleCname = `${style.smallStandardTitle}`;
    inptCname = `${style.smallStandardInput}`;
    btnCname = ` ${style.countryBtn}`;
  }

  return (
    <div className={style.inputBox}>
      <p className={`${titleCname} textColorMedium ${focused ? 'textColorMain' : ''}`}>
        {inputTitle}
      </p>
      <PhoneInput
        placeholder={placeHolder}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        containerClass={style.phoneInputContainer}
        inputClass={inptCname}
        buttonClass={btnCname}
        country="us"
        value={value}
        onChange={changeHandler}
        inputProps={{
          required,
          autocomplete: 'off',
          id,
        }}
        disabled={disabled}
      />
    </div>
  );
}
PhoneInputWrapper.defaultProps = {
  placeHolder: undefined,
  id: undefined,
  value: '',
  inputTitle: '',
  inputStyle: 'default_style',
  titleStyle: 'default_style',
  countryBtnStyle: 'default_style',
  standardInput: false,
  smallStandardInput: false,
  closedInput: false,
  required: undefined,
  disabled: false,
};
