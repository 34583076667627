import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import React, {
  ReactElement, useContext, useEffect, useState,
} from 'react';

import { gql, useQuery } from '@apollo/client';
import ScormWrapper from '../../../common_lib_front/components/scormWrapper/scormWrapper';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';

import RegistrationNavHeader from '../registrationNavHeader/registrationNavHeader';

import useCommunityOrientation from './useCommunityOrientation';
import style from './communityOrientation.module.css';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';

const GET_COMPANY_PROFILE = gql`
query getCompanyProfile {
    getCompanyProfile {
    success
    error
    data {
      businessType
    }
  }
}
`;

const GET_COMMUNITY_URL = gql`
query getBusinessTypeByName($name: String!) {
    getBusinessTypeByName(name: $name) {
    success
    error
    data {
      name
      educationUrl
      fee
    }
  }
}
`;

export function CommunityOrientation(): ReactElement {
  const { doCompleteHandle, redirect } = useCommunityOrientation();
  const { t } = useTranslation();
  const [businessType, setBusinessType] = useState('');
  const [communityOrientationUrl, setCommunityOrientationUrl] = useState<string | null>(null);
  const { communityId } = useContext(CommunityContext);

  const { loading } = useQuery(GET_COMPANY_PROFILE, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (d) => {
      if (d.getCompanyProfile.success) {
        setBusinessType(d.getCompanyProfile.data.businessType);
      }
    },
  });

  useEffect(() => {
    if (businessType) {
      backendClient.query({
        query: GET_COMMUNITY_URL,
        variables: {
          name: businessType,
        },
      }).then((res) => {
        if (res && res.data && res.data.getBusinessTypeByName
          && res.data.getBusinessTypeByName.data
          && res.data.getBusinessTypeByName.data.educationUrl) {
          setCommunityOrientationUrl(res.data.getBusinessTypeByName.data.educationUrl);
        }
      }).catch((error) => {
        // console.log(error);
      });
    }
  }, [businessType]);

  if (redirect) {
    return (
      <Redirect to={redirect} />
    );
  }

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <RegistrationNavHeader
          activeNum={2}
          title={`${t('Step 2')}: ${t('Community Orientation')}`}
          subtitle={t('')}
        />
        <div className={style.flexBox}>
          <div className={style.LMSWindow}>
            <ScormWrapper
              portal="vendor"
              continueHandler={() => {
                doCompleteHandle('education1');
              }}
              src={communityOrientationUrl || undefined}
            />
          </div>
        </div>

        {/* {
          ['oceanlakes'].indexOf(communityId) < 0 && (
            <div className={style.btn}>
              <GenericButton
                size="large"
                color="blue"
                title="Continue"
                clickHandler={() => {
                  doCompleteHandle('educationq');
                }}
              />
            </div>
          )
        } */}

      </div>
    </div>
  );
}

export default CommunityOrientation;
