import { gql, useMutation } from '@apollo/client';
import { ColDef } from 'ag-grid-community';
import React, { useMemo, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PassInfo, { passStatusMapTitles as ThisIsBadAndDumb, paymentStatusMapTitles } from '../../common_lib_front/types/passInfo';
import { formatDate } from '../../common_lib_front/utilities/formatDate';
import { useCreateApplication } from '../../hooks/createApplication';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import dataGridStyle from './myPassGrid.module.css';
import PopupCellRenderer from './popupCellRenderer';

const passStatusStyleMap: { [key: string]: string } = {
  'pending-renewal': dataGridStyle.pendingRenewal,
  active: dataGridStyle.active,
  inactive: dataGridStyle.readyToScan,
  incomplete: dataGridStyle.incomplete,
  expired: dataGridStyle.expired,
  'invite-deleted': dataGridStyle.expired,
  refunded: dataGridStyle.refunded,
  'incomplete-rental-car': dataGridStyle.incompleteRentalCar,
  suspended: dataGridStyle.suspended,
};

export const passStatusTitlesMap: { [Property in keyof typeof ThisIsBadAndDumb]: string } = {
  'pending-renewal': 'Pending Renewal',
  active: 'Activated',
  inactive: 'Ready to Scan',
  incomplete: 'Incomplete',
  'incomplete-rental-car': 'Must Complete Info',
  expired: 'Expired',
  refunded: 'Refunded',
  'invite-deleted': 'Deleted',
  'temporary-issued': 'Temporary Pass',
  suspended: 'Suspended',
};

const paymentStatusStyleMap: { [key: string]: string } = {
  paid: dataGridStyle.active,
  unpaid: dataGridStyle.incomplete,
  'no-charge': dataGridStyle.active,
  refunded: dataGridStyle.refunded,
};

const PassStatus = ({ value }: { value: string }) => (
  <div className={`${dataGridStyle.gridStatusBox} ${passStatusStyleMap[value] || ''}`}>
    {passStatusTitlesMap[value] || value}
  </div>
);

const PaymentStatus = ({ value }: { value: string }) => (
  <div className={`${dataGridStyle.gridStatusBox} ${paymentStatusStyleMap[value] || ''}`}>
    {paymentStatusMapTitles[value] || value}
  </div>
);

const dateFormatter = ({ value }: { value: string }) => formatDate(new Date(value));

const filterParams = {
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    const dateAsString = cellValue;
    const dateParts = dateAsString.split('-');
    const cellDate = new Date(
      Number(dateParts[0]),
      Number(dateParts[1]) - 1,
      Number(dateParts[2]),
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
};

const RENEW_PASSES = gql`
mutation initializeRenewal(
  $passIds: [String!]!
) {
  initializeRenewal(
    passIds: $passIds
  ) {
    success
    data {
      registrationId
    }
    error
  }
}
`;

export function useColDefs(props: {
  showExpired: boolean;
  setShowInfoDialog: (val: boolean) => void;
  setShareDialog: (val: boolean) => void;
  setPassDeleteMode: (val: boolean) => void;
  bulkSelectedData: PassInfo[] | undefined;
  setBulkSelectedData: (val: PassInfo[]) => void;
}): {
  colDefs: ColDef[];
  passInfoMode: 'pass' | 'vehicle';
  currentPass: PassInfo | null;
} {
  const [doRenewPass] = useMutation(RENEW_PASSES);
  const [doCreateApplication] = useCreateApplication();

  const {
    showExpired,
    setShareDialog,
    setShowInfoDialog,
    setPassDeleteMode,
    setBulkSelectedData,
    bulkSelectedData,
  } = props;
  const [passInfoMode, setPassInfoMode] = useState<'pass' | 'vehicle'>('pass');
  const [currentPass, setCurrentPass] = useState<PassInfo | null>(null);
  const { communityId } = useContext(CommunityContext);

  const history = useHistory();

  // header actions component
  const [dropdownValue] = useState('');

  const renewPasses = () => {
    doRenewPass({
      variables: {
        passIds: bulkSelectedData?.map((ele) => ele.passId) || [],
      },
      onCompleted: (d) => {
        if (d?.initializeRenewal.success) {
          doCreateApplication({
            variables: {
              registrationId: d?.initializeRenewal?.data[0]?.registrationId,
              type: 'vendor-renewal',
            },
            onCompleted: (complete) => {
              history.push(`/vendor/registration/${d?.initializeRenewal?.data[0]?.registrationId}/vehicle`, { type: 'renew' });
            },
          });
        }
      },
    });
  };

  const onClickHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const option = event.target.value;
    if (!bulkSelectedData || (bulkSelectedData && bulkSelectedData.length === 0)) {
      return;
    }
    if (option === 'renew') {
      renewPasses();
    }
    if (option === 'download') {
      setShowInfoDialog(true);
    }
    if (option === 'delete') {
      setPassDeleteMode(true);
    }
    if (option === 'vehicleinfo') {
      setPassInfoMode('vehicle');
    }
    if (option === 'passinfo') {
      setPassInfoMode('pass');
      setBulkSelectedData([]);
    }
  };

  const BulkActionsCellRenderer = () => (
    <select
      className={dataGridStyle.bulkSelectBox}
      value={dropdownValue}
      onChange={(event) => onClickHandler(event)}
    >
      <option value="bulkactions" selected>
        Bulk Actions
      </option>
      <option value="download">Download</option>
      <option value="renew">Renew</option>
      <option value="delete">Delete</option>
      {/* {
        passInfoMode !== 'vehicle'
          ? <option value="vehicleinfo">Vehicle Info</option>
          : <option value="passinfo">Pass Info</option>
      } */}
    </select>
  );

  const colDefs: ColDef[] = useMemo(() => ([
    {
      headerName: '',
      field: '',
      minWidth: 50,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      hide: passInfoMode !== 'pass',
    },
    {
      headerName: 'Pass Number',
      field: 'passNumber',
      minWidth: 100,
      hide: passInfoMode !== 'pass',
    },
    {
      headerName: 'Pass Type',
      field: 'passType',
      minWidth: 200,
      hide: passInfoMode !== 'pass',
    },
    {
      headerName: 'Name',
      field: 'primarydrivername',
      minWidth: 100,
      hide: false,
    },
    {
      headerName: 'License Plate',
      field: 'platenumber',
      minWidth: 100,
      // hide: passInfoMode !== 'vehicle',
    },
    {
      headerName: 'Start Date',
      field: 'startDate',
      filter: 'agDateColumnFilter',
      filterParams,
      valueFormatter: dateFormatter,
      minWidth: 100,
      // hide: passInfoMode !== 'pass',
    },
    {
      headerName: 'Valid Through',
      field: 'validThru',
      filter: 'agDateColumnFilter',
      filterParams,
      valueFormatter: dateFormatter,
      minWidth: 100,
      // hide: passInfoMode !== 'pass',
    },
    {
      headerName: 'Status',
      field: 'passStatus',
      cellRendererFramework: PassStatus,
      minWidth: 160,
      hide: false,
    },
    {
      headerName: 'Payment Status',
      field: 'paid',
      cellRendererFramework: PaymentStatus,
      minWidth: 160,
      // hide: passInfoMode !== 'pass',
    },
    {
      headerName: 'Add-ons',
      field: 'addons',
      minWidth: 100,
      hide: passInfoMode !== 'vehicle',
    },
    {
      headerName: 'Destination',
      field: 'destination',
      minWidth: 100,
      hide: passInfoMode !== 'vehicle',
    },
    {
      headerName: 'Pass Price',
      field: 'passPrice',
      hide: true,
    },
    {
      headerName: 'Make',
      field: 'make',
      minWidth: 100,
      hide: passInfoMode !== 'vehicle',
    },
    {
      headerName: 'Model',
      field: 'model',
      minWidth: 100,
      hide: passInfoMode !== 'vehicle',
    },
    {
      headerName: 'Color',
      field: 'color',
      minWidth: 100,
      hide: passInfoMode !== 'vehicle',
    },

    {
      headerName: ['oceanlakes'].indexOf(communityId) > -1 ? 'Identification #' : 'Driver\'s License #',
      field: 'identificationNumber',
      minWidth: 100,
      hide: passInfoMode !== 'vehicle',
    },
    {
      headerName: 'Identification Type',
      field: 'source',
      minWidth: 100,
      hide: !(passInfoMode === 'pass' && communityId === 'oceanlakes'),
    },
    {
      headerName: 'Pass Shared',
      field: 'passShared',
      minWidth: 100,
      hide: passInfoMode !== 'vehicle',
    },

    {
      headerName: '',
      hide: showExpired,
      pinned: true,
      field: 'actions',
      headerComponent: BulkActionsCellRenderer,
      cellRenderer: PopupCellRenderer,
      cellRendererParams: {
        setCurrentPass,
        setShowInfoDialog,
        setShareDialog,
        passInfoMode,
        setPassInfoMode,
        setPassDeleteMode,
        bulkSelectedData,
      },
      editable: false,
      sortable: false,
      filter: false,
      width: 160,
    },
  ]), [
    passInfoMode,
    setCurrentPass,
    setShareDialog,
    setShowInfoDialog,
    setPassInfoMode,
    setPassDeleteMode,
    showExpired,
    bulkSelectedData,
    communityId,
  ]);

  return {
    colDefs, passInfoMode, currentPass,
  };
}
