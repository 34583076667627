import { useContext, useState } from 'react';
import EmployeeInfo, { IAddEmployeeInfo, newEmployeeInfo } from '../../../common_lib_front/types/employeeInfo';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';

interface IUseAddUser {
  employees: IAddEmployeeInfo[],
  onAddMore: () => void,
  deleteOne: (index: number) => void,
  edit: (index: number, data: IAddEmployeeInfo) => void,
  errorStrings: string[],
  onSubmit: (employeeSelect : boolean) => boolean,
}

export default function useAddEmployee(): IUseAddUser {
  const [employees, setEmployees] = useState<IAddEmployeeInfo[]>([newEmployeeInfo()]);
  const [errorStrings, setErrorStrings] = useState<string[]>([]);
  const { communityId } = useContext(CommunityContext);

  const onAddMore = () => {
    setEmployees((prev) => [...prev, newEmployeeInfo()]);
  };

  const deleteOne = (index: number) => {
    setEmployees((prev) => prev.filter((_, i) => i !== index));
  };

  const edit = (index: number, data: IAddEmployeeInfo) => {
    setEmployees((prev) => prev?.map((e, i) => {
      if (i === index) {
        return data;
      }
      return e;
    }));
  };

  const onSubmit = (employeeSelect:boolean): boolean => {
    const requiredFields: (keyof EmployeeInfo)[] = ['firstName', 'lastName', 'phoneNumber', 'source', 'identificationNumber'];
    if (communityId !== 'palmettodunes') {
      requiredFields.push('email');
      requiredFields.push('file');
    }
    // if (employeeSelect) {
    //   requiredFields.push('source', 'identificationNumber');
    // }
    // if (!employeeSelect) {
    //   requiredFields.push('driverLc');
    // }
    const errors: string[] = employees?.map((e: IAddEmployeeInfo) => {
      if (requiredFields.some((k) => !e?.[k])) {
        return 'Please fill out all required information including upload of identification photo';
      }
      if (e.source !== 'other' && !e.driverLcExp?.trim()) {
        return 'Please fill out all required information including Expiration Date';
      }
      return '';
    });

    setErrorStrings(errors);

    if (errors?.some((err) => err)) {
      return false;
    }
    return true;
  };

  return {
    employees,
    onAddMore,
    deleteOne,
    edit,
    errorStrings,
    onSubmit,
  };
}
