import { gql } from '@apollo/client';
import React, {
  ReactElement,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../common_lib_front/components/inputField/inputField';
import { PassBuilderDateInputWithFetchDurtionInfo } from '../../common_lib_front/components/passBuilderDateRange';
import SearchSelector from '../../common_lib_front/components/searchSelector/searchSelector';
import TextArrayInput from '../../common_lib_front/components/textArrayInput/textArrayInput';
import VehicleTypeSelect from '../../common_lib_front/components/vehicleTypeSelect/vehicleTypeSelect';
import { useVehicleConfig } from '../../common_lib_front/hooks/useVehicleConfig';
import { backendResponse } from '../../common_lib_front/types/backendResponse';
import { IAddEmployeeInfo } from '../../common_lib_front/types/employeeInfo';
import PassInfo from '../../common_lib_front/types/passInfo';
import { VehicleInfoConfig } from '../../common_lib_front/types/vehicleInfo';
import { backendClient } from '../../common_lib_front/utilities/BackendAPI';
import { GET_EMPLOYEE_OBJ } from '../../hooks/useEmployee';
import usePassInfo from '../../hooks/usePassInfo';
import { ObjValidator } from '../../utilities/ObjValidatior';
import EmployeeSelectList from '../employeeSelectList/employeeSelectList';
import EmployeeSelect from './employeeSelect';
import style from './passForm.module.css';
import VehicleSelect from './vehicleSelect';

const ADDRESS_VALIDATION = gql`query AddressValidation($communitySymbol: String!, $address: String!) {
  addressValidation(communitySymbol: $communitySymbol, address: $address) {
    success
    error
    data {
      address
    }
  }
}`;

type ADDRESS_VALIDATION_VARS = {
  communitySymbol: string,
  address: string,
}

type ADDRESS_VALIDATION_RES = {
  addressValidation: backendResponse<{
    address: string,
  }[]>,
}

export type passFormInput = PassInfo & {
  employee?: GET_EMPLOYEE_OBJ;
  destination?: string;
}

const defaultFormConfig: VehicleInfoConfig = {
  make: 'disabled',
  model: 'disabled',
  type: 'disabled',
  color: 'disabled',
  vehicleSelect: 'required',
  employeeSelect: 'required',
  destination: 'required',
  employeeList: 'disabled',
  employeeSelectList: 'required',
};

const allItemList: Array<FieldItem> = [
  {
    title: 'Driver',
    name: 'driverSelect',
    value: 'required',
    displayOption: 'employee-table-select',
  },
  {
    title: 'Name',
    name: 'primaryDriverName',
    value: 'disabled',
    displayOption: 'text',
  },
  {
    title: 'Vehicle',
    name: 'vehicleSelect',
    value: 'required',
    displayOption: 'vehicle-table-select',
  },
  {
    title: 'Destination',
    name: 'destination',
    value: 'required',
    displayOption: 'address-select',
  },
  {
    title: 'Employees',
    name: 'employeeList',
    value: 'required',
    displayOption: 'text-array',
  },
  {
    title: 'Make',
    name: 'make',
    value: 'disabled',
    displayOption: 'text',
    hideIf: 'vehicleSelect',
  },
  {
    title: 'Model',
    pbField: 'vehicleModel',
    name: 'model',
    value: 'disabled',
    displayOption: 'text',
    hideIf: 'vehicleSelect',
  },
  {
    title: 'License Plate',
    pbField: 'licensePlate',
    name: 'plateNumber',
    value: 'disabled',
    displayOption: 'text',
    hideIf: 'vehicleSelect',
  },
  {
    title: 'Type',
    name: 'type',
    value: 'disabled',
    displayOption: 'type-select',
    hideIf: 'vehicleSelect',
  },
  {
    title: 'Year',
    name: 'year',
    value: 'disabled',
    displayOption: 'number',
    hideIf: 'vehicleSelect',
  },
  {
    title: 'Employee',
    name: 'employeeSelectList',
    value: 'disabled',
    displayOption: 'employee-select-list',
  },
];

function vehicleConfigToItemList(cfg: VehicleInfoConfig): FieldItem[] {
  return allItemList
    .map((itm) => ({
      ...itm,
      value: (() => {
        if (itm.hideIf && ['required', 'optional'].includes(cfg[itm.hideIf])) {
          return 'disabled';
        }
        if (itm.pbField) {
          return cfg[itm.pbField] || 'disabled';
        }
        return cfg[itm.name] || 'disabled';
      })(),
    }))
    .filter((itm) => itm.value === 'required' || itm.value === 'optional');
}

type PassFormProps = {
  data: passFormInput;
  idx: number;
  title?: string;
  removeHandler: () => void;
  updateHandler: (obj: Partial<passFormInput>) => void;
  allowRemove?: boolean;
  showViewBtn?: boolean;
  allowCompleteLater?: boolean;
  allowEditAddons?: boolean;
  isRenewal?: boolean;
};

type FieldItem = {
  title: string;
  pbField?: string;
  name: keyof VehicleInfoConfig;
  value: string;
  displayOption?: 'text' | 'address-select' | 'vehicle-table-select' | 'employee-table-select' | 'text-array' | 'type-select' | 'number' | 'employee-select-list';
  hideIf?: string;
};

export const PassForm = (props: PassFormProps): ReactElement => {
  const [open, setOpen] = useState<boolean>(true);
  const {
    idx,
    data,
    allowRemove,
    showViewBtn,
    updateHandler,
    title,
    allowCompleteLater,
    allowEditAddons,
    isRenewal,
  } = props;
  const willCompleteLater = useMemo(() => (data.status === 'incomplete-rental-car'), [data.status]);
  const { t } = useTranslation();
  const { communityId } = useContext(CommunityContext);

  const passInfo = usePassInfo({ passInfoId: data.passInfoId });
  const { data: vehicleConfigData } = useVehicleConfig({
    variables: {
      passInfoId: data.passInfoId,
    },
  });

  const itemList = useMemo(
    () => vehicleConfigToItemList(
      vehicleConfigData?.getVehicleInfoConfig.data || defaultFormConfig,
    ),
    [vehicleConfigData],
  );

  const isAddon = (item: any): boolean => data.addons?.indexOf(item.name) !== -1;

  const toggleAddon = (item: any) => {
    const updatedList = [...data.addons];
    const position = updatedList.indexOf(item.name);

    if (position === -1) {
      updatedList.push(item.name);
    } else {
      updatedList.splice(position, 1);
    }

    props.updateHandler({
      addons: updatedList,
    });
  };

  return (
    <div className={style.infoFormBox}>
      <div className={`${style.header} superDarkGrey `}>
        <div className={style.leftPart}>
          <div className={style.btnDown}>
            <GenericButton
              color="transparent"
              shape="drop-down"
              title={open ? '<' : '>'}
              clickHandler={() => setOpen(!open)}
            />
          </div>
          <strong className={`${style.header} textColorWhite `}>{title || `${idx + 1}. ${passInfo?.name}`}</strong>
        </div>
        {showViewBtn ? (
          <div className={style.view}>
            <Link to={{ pathname: '/vendor/pass-details', state: { data } }}>
              <GenericButton shape="view" title={t('View')} />
            </Link>
          </div>
        ) : null}
        {allowRemove ? (
          <div className={style.btnRemove}>
            <GenericButton
              color="transparent"
              title={t('Remove')}
              clickHandler={() => props.removeHandler()}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      {open ? (
        <div>
          {allowCompleteLater && vehicleConfigData?.getVehicleInfoConfig?.data?.completeLater !== 'disabled' && (
            <div className={style.inputLong}>
              <InputField
                inputType="checkbox"
                htmlFor={`incomplete-inpt-${idx}`}
                labelTitle="Complete Information Later"
                checkBox
                checked={data.status === 'incomplete-rental-car'}
                changeHandler={(e) => updateHandler({
                  status: e.target.checked
                    ? 'incomplete-rental-car'
                    : 'incomplete',
                })}
              />
              {willCompleteLater && (
                <div className={style.incompleteWarning}>
                  Notice: By checking this box, you will be required to complete
                  all the requested information later before this pass can be used.
                </div>
              )}
            </div>
          )}
          <div className={style.inputShortBox}>
            {itemList
              .filter((item) => item.value !== 'disabled')
              .map((item) => {
                switch (item.displayOption) {
                  case 'address-select':
                    return (
                      <div className={`${style.inputShort} ${style.searchSelect}`}>
                        <SearchSelector
                          htmlFor={`${item.name}-search-select-${idx}`}
                          title={`${t(item.title)}${item.value === 'required' ? '*' : ''}`}
                          value={data[item.name]}
                          changeHandler={(val) => updateHandler({ [item.name]: val })}
                          required={(willCompleteLater && allowCompleteLater) ? false : item.value === 'required'}
                          fetch={(address) => {
                            if (!address) {
                              return Promise.resolve([] as string[]);
                            }
                            return backendClient
                              .query<ADDRESS_VALIDATION_RES, ADDRESS_VALIDATION_VARS>({
                                query: ADDRESS_VALIDATION,
                                variables: {
                                  address,
                                  communitySymbol: communityId,
                                },
                              })
                              .then((response) => {
                                const validationData = response.data.addressValidation.data;
                                return validationData
                                  ? validationData.map((addr) => addr.address) : [];
                              })
                              .catch(() => []);
                          }}
                        />
                      </div>
                    );
                  case 'employee-table-select':
                    return (
                      <div className={style.inputShort}>
                        {/* control is nested in vehicle select eslint cannot find it */}
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor={`${idx}-employee-select`}>
                          {`${t(item.title)}${item.value === 'required' ? '*' : ''}`}
                          <EmployeeSelect
                            id={`${idx}-employee-select`}
                            required={(willCompleteLater && allowCompleteLater) ? false : item.value === 'required'}
                            // require drivers license for ocean lakes Employee Badge with DL
                            disabled={isRenewal}
                            filter={(emp) => new ObjValidator<IAddEmployeeInfo>({
                              combine: 'AND',
                              fields: [
                                { field: 'firstName' },
                                { field: 'lastName' },
                                {
                                  combine: 'OR',
                                  fields: [
                                    { field: 'email' }, { field: 'phoneNumber' },
                                  ],
                                },
                                {
                                  skip: ![
                                    'cc9e9e29-7864-4b90-8c42-70ab66040e7c', // employee badge w/ drivers license
                                    '7dd3024c-8691-44f4-b539-c898b27a5238', // employee badge w/ drone license
                                    'c7b333e7-1cf8-45ea-af88-b760cb5edcbd', // vendor badge w/ drivers license
                                    '812d89ae-91cc-4539-a8ce-839d8a739217', // vendor badge w/ drone license
                                  ].includes(passInfo.passInfoId),
                                  combine: 'OR',
                                  fields: [
                                    { field: 'driversLicenseNum' },
                                    {
                                      combine: 'AND',
                                      fields: [
                                        { field: 'identificationNumber' },
                                        {
                                          field: 'source',
                                          isValid: (val) => (typeof val === 'string' && val.toLowerCase() === 'driverlicense'),
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  combine: 'AND',
                                  fields: [
                                    {
                                      combine: 'OR',
                                      fields: [
                                        { field: 'identificationNumber' },
                                        { field: 'driversLicenseNum' },
                                      ],
                                    },
                                    {
                                      combine: 'OR',
                                      fields: [
                                        { field: 'driversLicenseImageUrl' },
                                        { field: 'employeePictureUrl' },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            }).isValid(emp)}
                            // filter={['11221d27-cbb6-4fdf-a0fe-a7364a6fe906',
                            // 'cc9e9e29-7864-4b90-8c42-70ab66040e7c'].includes(passInfo.passInfoId)
                            //   ? (emp) => !!emp.driversLicenseNum
                            //   : undefined}
                            employee={data.employee}
                            backupEmployeeId={data.vehicle.employeeId}
                            setEmployee={(employee) => updateHandler({ employee })}
                            displayPlaceholder={isRenewal ? data.vehicle?.primaryDriverName || '' : undefined}
                            validityTests={{
                              // force user to select/update an employee that does exist in the DB
                              REQUIRE_ID: (employees) => (
                                !!employees.find((em) => (
                                  em && em.employeeId === data.employee?.employeeId
                                ))
                              ),
                            }}
                          />
                        </label>
                      </div>
                    );
                  case 'vehicle-table-select':
                    return (
                      <div className={style.inputShort}>
                        {/* control is nested in vehicle select eslint cannot find it */}
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor={`${idx}-vehicle-select`}>
                          {`${t(item.title)}${item.value === 'required' ? '*' : ''}`}
                          <VehicleSelect
                            id={`${idx}-vehicle-select`}
                            required={(willCompleteLater && allowCompleteLater) ? false : item.value === 'required'}
                            vehicle={data.vehicle}
                            setVehicle={(vehicle) => {
                              // IMPORTANT
                              // do not overwrite vehicle id
                              const { vehicleId: _vid, ...allowedVals } = vehicle || {};
                              updateHandler({
                                vehicle: {
                                  ...data.vehicle,
                                  ...allowedVals,
                                  // color: vehicle?.color || data.vehicle.color,
                                  // fleetNumber: vehicle?.fleetNumber || data.vehicle.fleetNumber,
                                  // licensePlateState: vehicle?.licensePlateState ||
                                  // data.vehicle.licensePlateState,
                                  // registrationDocUrl: vehicle?.registrationDocUrl ||
                                  // data.vehicle.registrationDocUrl,
                                  // make: vehicle?.make || data.vehicle.make,
                                  // model: vehicle?.model || data.vehicle.model,
                                  // plateNumber: vehicle?.plateNumber || data.vehicle.plateNumber,
                                  // type: vehicle?.type || data.vehicle.type,
                                  // year: vehicle?.year || data.vehicle.year,
                                },
                              });
                            }}
                          />
                        </label>
                      </div>
                    );
                  case 'text-array':
                    return (
                      <div className={style.inputShort}>
                        <TextArrayInput
                          id={`${item.name}-inpt-${idx}`}
                          label={t(item.title) + (item.value === 'required' ? '*' : '')}
                          addLabel="Add Employee"
                          required={(willCompleteLater && allowCompleteLater) ? 0 : Number(item.value === 'required')}
                          value={data.vehicle[item.name] || []}
                          setValue={(nval) => updateHandler({
                            vehicle: {
                              ...data.vehicle,
                              [item.name]: nval,
                            },
                          })}
                        />
                      </div>
                    );
                  case 'employee-select-list':
                    return (
                      <div className={style.inputShort}>
                        <EmployeeSelectList
                          id={`${item.name}-inpt-${idx}`}
                          label={t(item.title) + (item.value === 'required' ? '*' : '')}
                          addLabel="Add Employee"
                          required={(willCompleteLater && allowCompleteLater) ? 0 : Number(item.value === 'required')}
                          value={data.vehicle[item.name] || []}
                          noHeader
                          topHead={(
                            <div>
                              <Link to="/vendor/popup/add-employee">Add Employee</Link>
                            </div>
                                )}
                          headers={[
                            {
                              label: 'Name',
                              field: (e) => `${e.firstName} ${e.lastName}`,
                            },
                          ]}
                          setValue={(nval) => updateHandler({
                            vehicle: {
                              ...data.vehicle,
                              [item.name]: nval,
                            },
                          })}
                          employee={data.employee}
                          backupEmployeeId={data.vehicle.employeeId}
                        />
                      </div>
                    );
                  case 'type-select':
                    return (
                      <div className={style.inputShort}>
                        <VehicleTypeSelect
                          htmlFor={`${item.name}-inpt-${idx}`}
                          labelTitle={t(item.title) + (item.value === 'required' ? '*' : '')}
                          required={(willCompleteLater && allowCompleteLater) ? false : item.value === 'required'}
                          inputValue={data.vehicle[item.name]}
                          changeHandler={(e) => updateHandler({
                            vehicle: {
                              ...data.vehicle,
                              [item.name]: e.target.value,
                            },
                          })}
                        />
                      </div>
                    );
                  case 'number':
                    return (
                      <div className={style.inputShort}>
                        <InputField
                          closedInput
                          htmlFor={`${item.name}-inpt-${idx}`}
                          required={(willCompleteLater && allowCompleteLater) ? false : item.value === 'required'}
                          labelTitle={
                         t(item.title) + (item.value === 'required' ? '*' : '')
                       }
                          inputType="number"
                          inputValue={data.vehicle[item.name]}
                          changeHandler={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            const val = parseFloat(e.target.value || '');
                            updateHandler({
                              vehicle: {
                                ...data.vehicle,
                                [item.name]: val,
                              },
                            });
                          }}
                        />
                      </div>

                    );
                  case 'text':
                    // fall through to default
                  default:
                    return (
                      <div className={style.inputShort}>
                        <InputField
                          closedInput
                          htmlFor={`${item.name}-inpt-${idx}`}
                          required={(willCompleteLater && allowCompleteLater) ? false : item.value === 'required'}
                          labelTitle={
                         t(item.title) + (item.value === 'required' ? '*' : '')
                       }
                          inputType="text"
                          inputValue={data.vehicle[item.name]}
                          changeHandler={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            updateHandler({
                              vehicle: {
                                ...data.vehicle,
                                [item.name]: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    );
                }
              })}
          </div>
          <div className={style.tableContainer}>
            <PassBuilderDateInputWithFetchDurtionInfo
              startDate={data.startDate}
              endDate={data.endDate}
              setter={updateHandler}
              passInfoId={data.passInfoId}
              autoSetDefault={(cfg) => [0, 1].includes(cfg.type)}
              id={`date-range-${idx}-inpt`}
              required
            />
          </div>
          {passInfo
            .priceInfo
            ?.addons
            ?.filter((item) => (allowEditAddons || isAddon(item)))
            ?.map((item, adx: number) => (
              <div className={style.addonContainer}>
                <input
                  type="checkbox"
                  className={style.checkBox}
                  id={`addon-${adx}-inpt-${idx}`}
                  checked={isAddon(item)}
                  onChange={() => toggleAddon(item)}
                  disabled={!allowEditAddons}
                />
                <label
                  className={style.addonLabel}
                  htmlFor={`addon-${adx}-inpt-${idx}`}
                >
                  {(!item.isFree && allowEditAddons) ? `$${item.price} | ` : ''}
                  {item.name}
                </label>
              </div>
            ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
PassForm.defaultProps = {
  allowRemove: true,
  showViewBtn: false,
  allowCompleteLater: true,
  allowEditAddons: true,
  title: undefined,
  isRenewal: false,
};

export default PassForm;
